<template>
  <Page class="page-my-profile page-show-my-profile">
    <template #title>Il mio profilo</template>

    <template #title-extra>
      <router-link class="btn btn-outline-light" :to="{ name: 'profile.edit' }">Modifica</router-link>
    </template>

    <template #default>
      <div class="table-responsive" v-if="profile">
        <table class="table table-condensed">
          <tbody>
          <tr>
            <th>Nome</th>
            <td>{{ profile.name }} {{ profile.surname }}</td>
          </tr>
          <tr>
            <th>Email</th>
            <td>{{ profile.email }}</td>
          </tr>
          </tbody>
        </table>
      </div>
    </template>
  </Page>
</template>

<script>

import $api from '@/libs/Api/services/api.js';
import rolesMixin from '@/mixins/roles.js';

import Page from '@/views/components/Private/Page.vue';

export default {
  mixins: [rolesMixin],
  components: {
    Page,
  },
  data: () => ({ profile: null }),
  async beforeRouteEnter (to, from, next) {
    try {
      const res = await Promise.all([
        $api.getMyProfile(),
      ]);
      return next(vm => {
        vm.profile = res[0]?.data || {};
      });
    } catch (error) {
      return next(from, vm => vm.$log.error(error));
    }
  },
};

</script>
